<!-- wxhome.vue -->
<template>
  <div class="loading-container">
    <Icon type="ios-loading" size="50" spin></Icon>
    <p>正在验证授权...</p>
  </div>
</template>

<script>
import { post } from '@/apis/restUtils';

export default {
  mounted() {
    // 获取 URL 中的 code 参数
    const code = new URLSearchParams(window.location.search).get('code');
    if (code) {
      // 发送请求到后台检查授权
      post('/wx/wxCheckUser', { code }, (response) => {
        console.log('wxCheckUser ...', response);
        if (response && response.data && response.data.status === 'success') {
          // let user = response.data.entity;
          let map = response.data.entity;
          console.log('response.data.entity ...', response.data.entity);
          let openid = map.openid;
          let user = map.user;
          console.log(openid, user);
          if(user === undefined || user === null){
            this.$router.push({ path: '/wxlogin', query: { openid } });
          } else {
          localStorage.setItem('UserName', user.username);
          localStorage.setItem('UserId', user.id);
          localStorage.setItem('Flag', true);
            // 授权成功，跳转到 wxindex.vue
            this.$router.push('/wxindex');
          }
        } else {
          // 未授权，跳转到 wxlogin.vue 并传递 code
          this.$Message.error('登录异常，请联系管理员。');
        }
      });
    } else {
      // 无 code 参数，直接跳转到 wxlogin.vue
      this.$router.push('/wxlogin');
    }
  }
};
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 占满手机屏幕高度 */
  width: 75%; /* 参考 wxlogin.vue 的宽度 */
  margin: 0 auto;
  font-size: 1.5rem;
  color: #666;
}
</style>